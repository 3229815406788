import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from 'reactstrap'

const Project = ({ project }) => (
  <div className="col-sm-12 col-md-12 col-lg-4 rounded-lg border m-2 shadow-2 hover-shadow-9">
    <Link to={project.link}>
      <Card>
        <CardImg
          top
          src={project.image}
          alt={project.name}
          className="rounded-lg"
          style={{ height: '350px' }}
        />
        <CardBody>
          <CardTitle>{project.name}</CardTitle>
          <CardSubtitle>{project.category}</CardSubtitle>
          <CardText>{project.summary}</CardText>
        </CardBody>
      </Card>
    </Link>
  </div>
)

Project.propTypes = {
  project: PropTypes.object,
}

export default Project
